.card-for-course{
    display: flex;
    flex-direction: column; 
    overflow: hidden;
    /* width: 100%; */
    flex: 1;
    /* height: auto; */
    /* gap: 10px; */
    border: 1px solid rgba(217, 217, 217, 0.91); 
    padding: 12px;
    border-radius: 5px;
    align-self: flex-start;
    transition: 0.5s;
    margin: 15px 0;
    /* height: 550px; */

}

.card-for-course:hover{
    transition: 0.5s;


    box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
    -webkit-box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
    -moz-box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
}
.card-for-course:hover{
    border: 1px solid lightblue; 
    transition: 0.5s;

}
/* @media(max-width:700px){
    .card-for-course{
        display: flex;
        flex-direction: column; 
        overflow: hidden;
        width: 280px;
        
        height: auto;
       
        border: 1px solid rgba(217, 217, 217, 0.91);
         
        padding:  20px; 
    }
    
}
  */
.cfc-img{  
width: 100%;
/* height:  260px;  */
border-radius: 6.6px ;
overflow: hidden;


}
.cfc-img img{  
width: 100%;
/* height:  220px;  */
border-radius: 6.6px;


}

.cfc-title{
    color: #00080a;
  padding: 10px 0 0;
  /* DisplayMedium */
  font-family: Inter;
  font-size: 21.002px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0 0 0; 

}

.cfc-text{
    color: var(--grey, #828282);
    padding: 10px 0 0 0;
    
    /* Mobile Paragraph */
    font-family: Inter;
    font-size: 14px;
    /* font-style: normal; */
    font-weight: 400;
    /* line-height: normal; */
    /* overflow: hidden; */ 
    text-align: left;
    justify-self: flex-start !important; 
  
}
.cfc-text p{ 
    padding: 0;
    min-height: 120px ;
}
.cfc-learn-more{
    padding: 10px 0 0 0;
    color: #166473;
    /* Mobile LInk */
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 3px;
}