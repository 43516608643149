.sliding-library{
    /* margin: 0 20px !important; */
    /* border: 2px solid red; */
    overflow: hidden;
    width: 118vw;
    z-index: 10;
    max-width: 134vw; 
    padding: 0 30px;
}
.sliding-library-blog{
    /* margin: 0 20px !important; */
    /* border: 2px solid red; */
    overflow: hidden;
    width: 100%;
    z-index: 10;
    /* max-width: 134vw;  */
    /* padding: 0 30px; */
}
@media(min-width:370px){
    .sliding-library{ 
        overflow: hidden;
        width: 115vw; 
        max-width: 134vw; 
        padding: 0 30px;
    }}
@media(min-width:420px){
    .sliding-library{ 
        overflow: hidden;
        width: 113vw; 
        max-width: 134vw; 
        padding: 0 30px;
    }}
@media(min-width:442px){
    .sliding-library{ 
        overflow: hidden;
        width: 111vw; 
        max-width: 134vw; 
        padding: 0 30px;
    }}
@media(min-width:565px){
    .sliding-library{ 
        overflow: hidden;
        width: 109vw; 
        max-width: 134vw; 
        padding: 0 30px;
    }}
@media(min-width:764px){
    .sliding-library{ 
        overflow: hidden;
        width: 108vw; 
        max-width: 134vw; 
        padding: 0 30px;
    }}