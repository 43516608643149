.mnew-hero-layout {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  position: relative;
  /* margin-bottom: 20px; */

}

.mnh-image {
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.mnh-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

/* .nh-image   {
   position: fixed;
  } */
.nh-shadow {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  /* generated shadow  */
  background: #000000;
  background: -webkit-linear-gradient(91deg, #000000 0%, rgba(77, 69, 69, 0) 50%, rgba(71, 74, 255, 0) 100%);
  background: linear-gradient(91deg, #000000 0%, rgba(77, 69, 69, 0) 50%, rgba(71, 74, 255, 0) 100%);
}

.mnh-text {
  z-index: 1;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  color: aliceblue;
  /* border: 2px solid green;  */
  font-family: Inter;
  /* font-style: normal; */
  font-weight: 200 !important;
  transform: translate(-50%);
  opacity: 0;
  transition: 0.5s;


}

.mnh-text h3 {
  /* font-size: 44px !important; */
  /* font-size: 2.5rem !important; */
  font-size: 9vw !important;

  /* line-height: 50px; */
  /* line-height: 50px; */
  padding: 0 0 0 10px;
  overflow: hidden;

  width: calc(100vw - 12px);
  /* word-wrap:keep-all; */
  /* overflow-wrap: keep-all;
  word-break: keep-all;
  line-break: 100%;
  -ms-word-break: keep-all;
  word-break: keep-all; */
  /* border: 1px solid red; */
}

.mnh-text h2 {
  color: #51a8b1;
  font-size: 2.5rem !important;
  /* font-size: 40px !important; */
  /* line-height: 70px; */

  padding: 0 0 0 10px;
  overflow: hidden;

}

.mnh-text h3 span {
  color: #51a8b1;
  /* font-size: 2.5rem !important; */
  font-size: 9vw !important;

  /* line-height: 70px; */

  /* padding: 0 0 0 10px; */
  overflow: hidden;


}

.mab-toggler {
  right: 80px;
  z-index: 66;
  position: absolute;
  bottom: 0px;
  height: 100%;
  /* width: 100%;  */
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* border: 2px solid red; */

}

.visibleCarouselText {
  /* transition: 0.5s !important; */
  opacity: 1 !important;
  transform: translate(0%) !important;
  overflow: hidden;
}

.mnhslder {
  overflow: hidden;
  /* border: 2px solid red; */
  padding: 0px !important;
  /* background: #000000; */
  z-index: -1;

}

.mtogler {
  /* justify-content: space-between; */
  /* height: 5px; */
  /* align-self: center; */
  /* padding: 40px 0  20px 0; */
  /* gap: 10px; */
  fill: darkcyan;
  /* border: 2px solid red; */
  align-self: flex-start !important;
  width: 90%;
  padding: 0 10px;
  margin-top: 10px;
  /* height: 30px; */


}



.mloading-header {
  background: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50%;

}

.mnhslder {
  background-color: black;

}