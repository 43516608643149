@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
.pageTitle {
  /* width: 90%; */
  color: #000;
  position: relative;
  text-align: center;
  align-items: center;
  align-self: center;
  padding: 30px 0 0 0;
}
* {
  padding: 0%;

  margin: 0%;
}
.pageTitle h1 {
  color: #166473;
  text-align: center;
  /* Mobile Herotext */
  font-family: Inter;
  font-size: 30.44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: center;
  position: relative;
}

.heroImg img {
  /* height: 500px; */
  width: 100%;
  /* max-width:400px;  */
  /* margin-top: 70px; */
  /* background-image: url('assets/training.png'); */
  background-position: center;
  background-size: cover;
  /* position: relative; */
  /* background-attachment: fixed; */
}


.hideOnMobile{
  height: 0;
  position: absolute;
  visibility: hidden;
  }
.coursesSection-grid {
  display: flex;
  padding: 0px 40px 10px 40px;
  flex-direction: row;
  justify-content: left;
  align-items: start;
  gap: 20px;
  /* align-self: stretch; */
  width: 100%;
  border:3px solid rgb(255, 0, 0);
}
.c-s-div{
  display: flex;
  justify-content: center;
  width:100%;
  height: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
}
/* @media(min-width:1012px){
  .coursesSection{
    grid-template-columns: repeat(3, 315px);
  }
}
@media(max-width:1012px){
  .coursesSection{
    grid-template-columns: repeat(2, 315px);
  }
}*/
@media(max-width:640px){
  .coursesSection{
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 30px !important; 
  }
 
} 
.coursesSection {
  display: grid;  
  justify-content: space-around;
  align-items:flex-start;
  gap: 20px; 
  grid-template-columns: repeat(2, 1fr ); 
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;



} 
.sectionTitle {
  height: auto;
  align-self: stretch;
  padding: 0px 0 60px 0;
  /* border:2px solid red */
   
}
@media(max-width:700px){
  .sectionTitle h2 { 
    /* padding:  40px 10px 0 10px !important; */
  }
  .sectionTitle { 
    padding: 50px 0 30px 0;
     
  }
}

.sectionTitle h2 {
  color:  #166473;
  text-align: center;
  /* DisplayLarge */
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding:   0px 10px 0 10px;
}
@media (max-width:600px){
  .sectionTitle h2 { 
    font-size: 40px; 
  }
}
.BC {
  /* transform: translateY(-10%); */
  transition: 0.2s;
  opacity: 0;
}

.fade-in {
  /* transform: translateY(0) !important; */
  opacity: 1 !important;
  transition: 0.2s;
}

.trainingTitle {
  position: relative;
  display: flex;
  padding: 60px 0 0 0;
  flex-direction: column;
  height: auto;
  /* border: 1px solid rgba(217, 217, 217, 0.91); */ 
  align-items: center !important;
  /* position: relative; */
}
.trainingTitle .image {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-items: center !important; 
}
.trainingTitle .image img {
  /* background-color: #fff; */

  /* flex: 1 0 0; */
  /* align-self: stretch; */
  /* background: var(--darker-cyan, #166473); */
  /* align-items: center !important; */
   width: 100%;
   overflow:hidden;
/*
  max-width: fit-content; */
  height: 95vb;
  position: relative; 
  object-fit:none;
}

.side-text {
  font-family: Inter;
  font-size: 20px;
  color: #fff;
  text-align: left;
  position: absolute;

  bottom: 0px;
  left: 10px;
  height: fit-content;
  margin: auto;
  padding-bottom: 75px;
  transform: translateX(-100%);
  transition: 0.2s;
  opacity: 0;
  line-height: 40px;
}
.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -300px 160px -160px #000000;
    top: 0;
    left: 0;
}

.fade-text-in {
  transform: translateX(0%);
  transition: 1s;
  opacity: 1;
}

.side-text h1 span {
  color: #51a8b1;
  font-size: 75px;
  line-height: 65px;
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 9.26%,
    rgba(0, 0, 0, 0) 100%
  ); */
}
.side-text h1 {
  line-height: 40px;
}

.video-section{
    padding: 10px 0 0 0;
}
.video-section img{
    width: 100%;
}





          /* card api  */

.card-wrap{
  display: flex;
  flex-wrap: wrap;

}

.row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 100%; */
  border: 1px solid black;
}

.video-placeholder{
  width: 100%;
  height: 100vh;
  background-color: #000;
  margin: 20px 0;
  /* color: white; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.global-component-padding{
  margin:   0;

}
@media(min-width:700px){
  .video-placeholder{
    margin: 100px 0;
  }
  .global-component-padding{
    margin: 80px 0; 
    display: flex;
    flex-direction: column;
    /* border: 2px solid; */
    
  }
}
.grey-back{
  background: gainsboro !important ;
  z-index: 99;
   border: 1px solid gainsboro !important ;
   /* width: 100vw; */
   /* height: max-content!; */
   margin: 30px 0;
   overflow: hidden;
}
@media(max-width:700x){
  .grey-back{
  margin: 0px;}
}
.elfwidget{
  position: relative;
  z-index: 0;

}
.video-container{
  min-height: 500px;
}
.overlay {
  z-index: 4;
  position: absolute;  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);  
}
.overlay h2{
  margin: 0;
  padding: 20px  20%;
  font-family: Inter;

}

.overlay h2{
  font-size: 30px;
  color: white
   /* #62bdcf; */
}
.overlay h2 span{
  font-size: 30px;
  color: 
   #62bdcf;
}
/* 
.overlay button {
  padding: 20px 90px;
  font-size: 1rem;
  border: none;
 
  color: #fff;
  font-family: Inter;
  background:  #166473;
} */

.video-buttons{
  padding: 20px;
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.video-buttons Button{
 cursor: pointer;
  
}

@media(max-width:700px){
  .video-buttons{
   flex-direction: column;
   
  }
}
/* 
#my-widget-id{
  width: 100%;  
  height: 100vh;

} */