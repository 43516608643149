.about-page {
  display: flex;

  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* border: 1px solid green; */
  margin: 0 15px !important;
}
@media (max-width: 700px) {
  .about-serum {
    flex-direction: column;
    padding: 0 !important;
  }
  .as-image {
    /* min-height: 600px !important; */
    /* max-height: auto !important; */
  }

  .as-image iframe {
      max-height: 500px !important;
    min-height: 300px !important;
    object-fit: cover;
  }
  .as-title,
  .t-title, .welcome  {
    font-size: 15px !important;
    padding-top: 15px;
  }
}
.about-serum {
  align-self: center;
  display: flex;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  gap: 20px;
  padding: 50px 0;
  /* border: 1px solid rgb(0, 38, 255); */
  /* height: 400px; */
}

.as-image {
  /* border: 1px solid red; */

  /* width: 50%; */
  flex: 1;
  /* max-width: 500px; */
  /* height: 400px; */
  /* height: 350px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  /* padding:0 20px; */
  max-height: 400px;
  background-image: url("./assets/plc.png");
  background-position:center;
  background-size:cover;

}

.as-image img {
  width: 100% !important;

  height: 100% !important;
  object-fit: cover;
}

.as-image iframe {
  width: 225%   ;

  height: 120%  ;
  object-fit: cover;
}
.as-texts {
  /* border: 1px solid red; */
  flex: 1;

  /* max-width: 500px; */
  /* border: 1px solid green; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  font-family: Inter;
  /* padding:0 20px; */
  /* max-height: 580px; */
}
.as-title,
.t-title, .welcome {
  font-size: 20px;
  color: var(--dark-color);
  margin: 5px 0px 0 0px;
}

.as-about-para p {
  text-align: left;
  color: #828282;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 14px;
  text-align: justify !important;

}
.as-about-para p span {
  color: #00080a;
  font-weight: bold;
}

/* FOUNDER SECTION  */
@media (max-width: 700px) {
  .f-row-pls {
    flex-direction: column-reverse;
    padding-top: 30px !important;
  }
  .f-texts {
    padding-top: 20px !important;
    gap: 0px !important;
  }

  .f-texts,
  .avatar-section {
    width: 100%;
  }
}
/* @media(max-width:1100px){
     
      .f-para p { 
  padding-left: 5px !important;
  padding-right: 5px !important;  
}}
  */

@media (max-width: 700px) {
  .f-texts,
  .avatar-section {
    width: 100% !important;
  }
}
.founder {
  background: var(--white-color);
  font-family: Inter;
  /* padding: 80px 10vw; */
  /* max-width: 1200px !important; */
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-around;
  align-items: center !important;
  padding: 0 15px;
  flex: 1;
}
.f-row-pls {
  position: relative;
  font-family: Inter;
  max-width: 1200px;
  /* max-width: 1200px !important; */
  display: flex;
  /* border: 1px solid blue; */
  justify-content: space-around;
  align-items: center !important;
  /* padding: 0 12vw; */
  flex: 1;
  padding: 40px 0px;
  /* margin: 0 15px; */
}
.f-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  /* max-width: 500px; */
  /* border: 1px solid yellowgreen; */
  width: 50%;
  /* flex: 1; */
}
.welcome {
  font-family:Inter;
  color: var(--dark-color);
  font-weight: lighter;
  font-size: 20px;
  /* border: 1px solid red; */
  padding: 0 !important;
}
.welcome h1 {
  padding: 0 !important;
  margin: 0;
}

.f-para p {
  text-align: justify !important;
  color: #828282;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 20px;
  padding-top: 20px;

  font-size: 14px;
  /* border: 1px solid red; */
}
.signiture {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  /* border: 1px solid red; */
}
.signiture img {
  /* width: 100px; */
  height: 50px;
}
.avatar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  /* max-width: 500px; */
  width: 50%;
}
.avatar {
  width: 270px;
  height: 270px;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.doc-name {
  font-size: 10px;
  padding: 30px 0 0 0;
  font-weight: 300;
}
.emp-name h2{
  font-size: 18px;
  padding: 30px 0 0 0;
  font-weight: normal;
}
.doc-text p {
  color: #828282;
  padding: 10px 0 30px 0;
  font-size: 12px;
  width: 250px;
  text-align: center;
  /* height: 30px; */
  /* border: 2px solid red; */
}
.emp-text p {
  color: #828282;
  padding: 10px 0 30px 0;
  font-size: 14px;
  width: 250px;
  text-align: center;
  /* height: 30px; */
  /* border: 2px solid red; */
}
@media (max-width: 850px) {
  .doc-text p {
    /* height: 10px; */
  }
}

/* MEET THE TEAM SECTION  */

@media(max-width:700px){
    .single-emp{
        padding-bottom:20px;
    }
}
.team-section {
  /* border: 1px solid green; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px 90px 0px;
  font-family: Inter;
}

.t-title {
  color: var(--dark-color);
  /* font-size: 25px; */
  /* border: 1px solid red; */
  text-align: center;
  padding: 40px 0 30px 0;
}
.team {
  /* border: 1px solid red; */
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
@media (max-width: 700px) {
  .team {
    gap: 20px !important;
  }
  
.team-section { 
  padding-bottom: 10px; 
}
}
.team:last-child {
  flex-grow: 100;
}
.single-emp {
  /* border: 1px solid red; */
  /* width: 33%; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;

}
@media(max-width:549px){
  
.single-emp {
 
  max-width: 100%;

}
}

.emp-img {
  display: flex;
  align-items: center;
  border-radius: 10px;
  /* width: auto; */
  /* height: 200px; */
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  /* border: 1px solid blue; */
}

.emp-img img {
  width: 100%;
  /* padding-bottom: 40px; */
}
