@media (min-width: 1000px) {
  .desktop-nav-bar {
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* background: rgba(255, 255, 255, 0.9); */

  justify-content:center;

    /* backdrop-filter: blur(2px); */
  }
  .desktop header{
    /* position: fixed;
    top: 0; */
  }
  .desktop  {
    /* padding: 0 15%; */
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    /* background: rgba(255, 255, 255, 0.9); */

  justify-content:center;

    /* backdrop-filter: blur(2px); */
  }
  .desktop-nav-links{
    align-items: center;
    justify-content: center;
  }
  .desktop-nav-links ul{
    align-items: center;
    display: flex;
    font-family: Inter;
    list-style: none; 
    text-align: center;
    /* align-self: center; */
    justify-content: center;
    padding: 8px 0 0 0;
    
  }

  .desktop-nav-links ul li{
    padding: 0px 20px;
    font-size: 12px;
    align-self: center;
  }

  .desktop-nav-links ul li .menuLink{
    padding: 0px 20px;
    font-size: 12px;
    align-items: center;
   

  }
  .desktop-nav-links ul li .menuLink{
    padding: 0px 20px;
    font-size: 12px;
    align-items: center;

  }
  .desktop {
    /* height: 40px ; */
    opacity: 1 !important;
  }
 
  .mobile-nav-bar{
    height: 0  ;
    z-index: -6;

    opacity: 0;
  }
}
.desktop {
  /* height: 0  ; */
  opacity: 0;
}

.mobile{
  height: auto;
  z-index: 100;
}