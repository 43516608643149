.togler{
    justify-content: center;
    /* height: 5px; */
    align-self: center;
    /* padding: 40px 0  20px 0; */
    gap: 10px;
    fill:darkcyan
    
}

*{
    margin: 0 0;
    padding: 0 0;
}
.back-toggle, .forward-toggle{
    cursor: pointer;
}
    
.back-toggle:hover, .forward-toggle:hover{
   color: darkgray

}

.sh{
  text-shadow :  12px 32px 42px 12px rgba(0, 0, 0, 0.9);

}
 

.leftPad{
    padding-left: 20px;
}
.rightPad{
    padding-right: 20px;
}