.NewMobileLanding {
  overflow: hidden;
  height: 100vh;
}
.nml-grid {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nml-header {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #51a8b1;
  color: white;
}

.nml-logo img {
  width: 55px;
  height: 70;
  padding-left: 10px;
}

.nml-header p {
  text-align: right;
  padding: 10px;
  font-size: 12px;
}
.nml-text-parent{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 500;
    font-family: Inter;
    color: white;
    z-index: 2;
    /* border: 2px solid red; */
}
.nml-text {
  /* position: absolute;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 500;
  font-family: Inter;
  color: white;
  z-index: 2;
  border: 2px solid red; */
  font-size: 36px;
    font-weight: 500;
    font-family: Inter;
    text-align: center;

}
.nml-service {
  /* flex: 1;
  position: relative;
  overflow: hidden; */
}

.nml-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nml-training {
  /* flex: 1;
  position: relative;
  overflow: hidden; */
}
.newcenterml{
    flex: 1;
  position: relative;
  overflow: hidden;

}

.whiteSpacemiddle {
  background: white;
  height: 5px;
}

.nml-footer {
  width: 100%;

  padding: 10px 0;
  display: flex;
  justify-content: space-evenly;

  background: #51a8b1;
  color: white;
}
.nml-footer a img {
  /* border: 2px solid red; */
  align-self: center;
  justify-self: center;
}
.nml-footer a {
  /* border: 2px solid yellow; */
  align-items: center;
  display: flex;
  justify-content: flex-start;
  /* width: 1px; */
}
.nml-footer {
  /* border: 2px solid blue; */
  /* align-self: flex-end;
justify-self: flex-end; */
}

.moving-shadow {
  box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;
  -webkit-box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;
  -moz-box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;

  filter: brightness(0.8) saturate(33%);
  transition: 1s !important;
}

.nml-none {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;

  filter: brightness(1) saturate(100%);
  transition: 1s !important;
}

.nmlshadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: none;
  /* border-radius: 20px; */
  top: 0;
  left: 0;
  z-index: 1;
  transition: 1s !important;
}
