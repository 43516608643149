.sslibrary-card {
  overflow: hidden;
  width: 100%;
  /* border: 2px solid yellow; */
  height: 350px;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  /* margin: 10px;
  padding: 2px; */
}
.sslibrary-card:hover{
  .sslc-text{
  transition: 0.5s;
  font-family: Inter;
  font-size: 18px !important;

  font-weight: 300 !important;
  position: absolute;
  z-index: 45;
  /* border: 2px solid red; */
  bottom: 0;
  width: 100%;
  height: 100%;


  

    box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
    -webkit-box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
    -moz-box-shadow: 1px 0px 23px -6px rgba(0,0,0,0.46);
  }

  .sslc-text h3 {
  transition: 0.2s;

    font-weight: 700 !important;
    text-decoration: none;
    font-size: 26px;
    /* padding: 0 20px 0 0 ; */
  }
  .sslc-text h3 {
  transition: 0.01s;
/* 
    font-weight: 700 !important;
    text-decoration: none;
    font-size: 26px; */
    padding: 0 20px 0 0 ;
  }
  .sslc-text h5 {
  transition: 0.07s;

    font-size: 19px;
    padding: 5px 0 0 0;
    font-weight: 400;
  }
}

.sslc-image {

  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* border: 2px solid green; */
/* margin: 10px; */
}
.sslc-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sslc-text {
  transition: 0.5s;

  font-family: Inter;
  font-size: 12px;

  font-weight: 300 !important;
  position: absolute;
  z-index: 45;
  /* border: 2px solid red; */
  bottom: 0;
  width: 100%;
  height: 30%;
  border: 1px solid rgba(255, 255, 255, 0.01);
  background: linear-gradient(
      0deg,
      rgba(81, 168, 177, 0.2) 0%,
      rgba(81, 168, 177, 0.2) 100%
    ),
    rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6.800000190734863px);
  border-left: 8px solid #51a8b1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px 0 5px;
  color: white;
}
.sslc-text h3 {
  font-weight: 700 !important;
  text-decoration: none;
  font-size: large;
  padding: 0 15px 0 0 ;
}
.sslc-text h5 {
  font-size: 15px;
  padding: 5px 0 0 0;
  font-weight: 400;
}

@media (max-width: 700px) {
  /* .slibrary-card{
        max-width: auto;
        min-width: 100vw
    }
    .slc-text{
        font-family: Inter;
        font-size: 12px;
        
    }
    .slc-image {
       height: 150px;
       overflow: hidden;
       display: flex;
       align-items: center;
     
     } */
}
  .sslink h3 {
  color: black;
}

.sslc-short{
    color: #828282;
    font-size: 12px;
    padding: 5px 0;
}

.sslc-description{
    height: 100px;
}
.sslc-read-more{
    font-family: Inter;
    text-decoration: underline;
    color: black !important;
    font-weight: 400;
    
}

.sliding-lib-card{
    max-width:95%;
    margin: 0;
    padding: 0;
    /* border: 2px solid blue; */
    overflow: hidden;

   
}
@media(min-width: 700px){

  .sliding-lib-card{
    max-width:98%; 

   
}


}
 
/*  
 .slick-slide {
    margin: 0 5px;
}

 
.slick-list {
    margin: 0 -5px;
} */