.services {
  height: 0;
  opacity: 0;
}
.service-carousel,
.desktop-testimony {
  height: 0;
  opacity: 0;
}
.gal {
  z-index: 0 !important;
}
.desktop {
  height: 0;
  resize: vertical;
  z-index: 100;
}
.solid {
  margin: 0 170px;
}
footer {
  visibility: visible;
  height: auto !important;
  max-height: auto !important;
  padding: auto !important;
}
.mobile-testimony {
  opacity: 1;
  height: auto;
  /* padding: auto   */

  background: #f5f5f7;
}
.desktop-button,
.big-footer-text,
.buttons-footer,
.address,
.logo-and-name,
.logo-and-name .serum-logo,
.contact-us-section,
.first-row {
  /* padding: 0 0;
  margin: 0 0;*/
  height: 0px;
  visibility: hidden;
  position: fixed;
  /* transform: translate(-50%); */
}

.solid-dark-alone {
  border-top: 0px solid transparent;
}
.solid-dark-first {
  border-top: 0px solid transparent;
}

@media (min-width: 700px) {
  .mobil-land {
    height: 0;
    visibility: hidden;
  }
  .desk-land {
    height: auto;
    visibility: visible;
  }
  .hideOnMobile {
    height: auto;
    position: relative;
    visibility: visible;
  }

  .desktop-button,
  .big-footer-text,
  .buttons-footer,
  .address,
  .logo-and-name,
  .logo-and-name .serum-logo,
  .contact-us-section,
  .first-row {
    /* padding: auto;
  margin: auto; */
    height: auto;
    visibility: visible;
    position: relative;
    /* transform: translate(0%); */
  }

  .desktop-testimony {
    background: #fff;
    padding: 50px 50px 50px 60px;
  }
  .mobile-testimony {
    opacity: 0;
    height: 0;
  }
  .desktop-footer div,
  .desktop-footer hr,
  .desktop-footer div div div {
    opacity: 1;
    height: auto;
    font-family: Inter;
    /* padding: auto   */
  }

  .desktop-testimony {
    height: auto;
    opacity: 1;
    background: #fff;
  }
  .service-carousel,
  .desktop-testimony {
    height: auto;
    opacity: 1;
  }
  .services {
    height: auto;
    opacity: 1;
    min-height: 100vh;
    width: 100%;
    /* border-radius: 6.626px;
    border: 1px solid rgba(217, 217, 217, 0.91); */
  }
  .service-carousel {
    display: flex;
    flex-direction: column;
    /* max-height: 100%;
  max-width: 50%; */
    /* right:0; */
    /* width: 100%; */
    /* padding: 50px 0 0 0 ; */
    /* left:0; */
    /* height: 100vh;
  height: 100vh; */
    width: 100%;
    justify-content: start;
    align-items: space-around;
    /*  align-self: right;
  justify-items: right;
  align-content: right;  */
    /* border-radius:  6.626px;
    border: 1px solid rgba(217, 217, 217, 0.91);  */
  }
  .hero-image {
    /* width: 1371px;
height: 914px; */
    /* background-image: url("./assets/blackface.png"); */
    background-position: center;
    background-size: auto;
    height: 400px;
    width: 63% !important;
    border-radius: 6.626px;
    border: 1px solid rgba(217, 217, 217, 0.91);
    /* display: flex !important; */

    /* justify-content: right !important; */

    /* display: flex; */
  }
  /* .hero-image img{size:fill;
        

    } */
  .card {
    justify-content: space-between;
    align-items: center;
    /* border-radius:  6.626px;
    border: 1px solid rgba(217, 217, 217, 0.91);  */
    width: 97%;
    margin: 100px 0;
  }
  .hero-text {
    padding-left: 10px;
    text-align: left;
    color: var(--darker-cyan, #166473);

    /* DisplyXXLarge */
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .solid-dark-alone {
    border-top: 7px solid #000;
    /* z-index: 6; */
    padding: 0 0;
    margin: 0 0;
  }
  .solid-dark-first {
    margin: 60px 0 0 0;
    padding: 0 0;
    border-bottom: 7px solid #000;
  }
  .heroComp,
  .trainingTitle,
  .video-section {
    visibility: hidden;
    height: 0vh !important;
    max-height: 0vh !important;
    padding: 0 0 !important;
  }

  .services-card-desktop {
    display: flex;
    gap: 10px !important;
    margin: 0 10px;
    /* max-width: 1300px; */
    justify-self: center;
    align-self: center;
    flex-direction: row !important;
    flex-wrap: wrap;
    /* border: 2px solid red; */
    justify-content: center !important;
    align-items: center;
  }
  .hugger { 
    /* border: 2px solid blue; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,  1fr ) !important;  
    gap: 20px;
    /* grid-template-columns:  repeat(auto, minmax(auto, fit-content ));  */

  }
  
  .serviceCardContainer {
    /* width: 50%; */
  }
  .explore-more {
    padding-top: 40px;
    font-size: 19px;
  }
  .explore-more h4 {
    /* padding-top: 40px; */
    font-size: 19px;
  }
  .CTA {
    /* padding: 10% 0; */
    margin: 0 0;
  }
  .second-row {
    display: flex;
    justify-content: space-between;
    /* padding: 0 90px; */
    margin: 20px 0;
    /* border: 2px solid red; */
    width: 100%;
    max-width: 1200px;
  }

  .logo-and-name {
    display: flex;
    align-items: center;
  }
  .address {
    /* padding: 0 80px; */
    /* border: 2px solid red; */
    flex: 1;

  }
  .address p {
    padding-left: 0px;
    padding-top: 0px;
    color: #9e9e9e;

  }

  .contact-us-section {
    line-height: 30px;
    /* padding: 0 70px; */
    /* border: 2px solid red; */
  }
  .contact-us-section p a{
    color: #166473;
  }
  .contact-us-section p {
    color: #9e9e9e;
    padding: 10px 0 0 0;
  }
  .desktop-footer  {
   /* border: 2px solid red; */
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: 0 15px;
  }

  .third-row {
    display: flex;
    justify-content: space-between;
    margin: 30px 10px 30px 10px;
    width: 100%;
    max-width: 1200px;
    /* border: 2px solid red; */
    align-items: center;
  }

  .third-row p{
    padding: 0;
  }
  .tiktokIcon{
    width: 19px;
    fill: #166473;
  }

  .social-media-footer img {
    padding: 0 20px 0 0;
  }
  .first-row {
    display: flex;
    justify-content: space-between;
    padding: 80px 0 30px 0;
    width: 100%;
    max-width: 1200px;
    /* border: 2px solid red; */
  }

  .big-footer-text {
    font-size: 25px;
    /* padding: 0px 40px 0px 0px; */
    width:50%;
    text-align: left;
    justify-content: flex-start;
    color: #166473;
  }
  .big-footer-text h3 {
    font-weight: 600;
  }

  .buttons-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 19px;
  }
}

.services-card-desktop {
  display: flex;
  gap: 10px;
  margin: 0 0;
  max-width: 1200px !important;
  justify-self: center;
  align-self: center;
  /* flex-wrap: wrap;
justify-content: center; */
  /* border: 1px solid red; */
  flex-direction: column;
  overflow: hidden;
}
.hugger { 
  /* border: 2px solid blue; */

  display: grid;
  grid-template-columns: repeat(1,  100% );  
  gap: 10px; 
  /* grid-template-columns:  repeat(auto, minmax(auto, fit-content ));  */
  /* grid-template-rows: repeat(auto, 50vh ) !important;   */
  overflow: hidden;


}

@media(max-width:950px){
  .hugger { 
    /* border: 2px solid blue; */
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2,  1fr ) !important;  
    gap: 10px;
    /* grid-template-columns:  repeat(auto, minmax(auto, fit-content ));  */

  }
}
@media(max-width:700px){
  .hugger { 
    /* border: 2px solid blue; */
    /* width: 100%; */

    display: grid;
    grid-template-columns: repeat(1,  95vw ) !important;  
    row-gap: 10px;
    /* grid-template-columns:  repeat(auto, minmax(auto, fit-content ));  */
    overflow: hidden;
    justify-content: center;
  }
}

.tiktokIcon{
  width: 19px;
  fill: #166473;
}