.library-card{
    padding-bottom: 20px;
   width:100%;
    /* border: 2px solid greenyellow; */

}

.lc-image{
    width:100%;
    height: 350px;
    overflow: hidden;
}
.lc-image img{
   width:100%;
   height: 100%;
   object-fit: cover;
    /* height: 300px; */
    /* border: 2px solid red; */

}

.lc-text{
    font-family: Inter;
    font-size: 12px;
    padding: 5px 0;
    font-weight: 300 !important;
}
.lc-text h3{ 
    font-weight: 500 !important;
    text-decoration: underline;
}
.lc-text h5{
    color: #828282;
    font-size: 10px;
    padding: 5px 0 0 0;

}

@media(max-width:700px){
    .library-card{
        max-width: auto;
        min-width: 100vw
    }
    .lc-text{
        font-family: Inter;
        font-size: 12px;
        padding: 5px 10px;
    }
    .lc-image {
       height: 150px;
       overflow: hidden;
       display: flex;
       align-items: center;
     
     }
     
}

.link h3{
    text-decoration: underline !important;
    color: black;

}