.before-after-container {
  overflow: hidden;
}
.before-after-parent img:-moz-selection {
  color: transparent;
  background: transparent;
}

.before-after-parent img::selection {
  color: transparent;
  background: transparent;
}

.before-after-parent {
  /* border-radius: 5px; */
  width: 100%;
  height: 320px;
  background: #efefef;
  position: relative;
  /* border: 2px solid #533535; */
  overflow: hidden;
}
.before-after-parent img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.before-after-resizer {
  /* border: 1px solid #533535; */

  position: absolute;
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  /* width: 2px; */
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
  overflow: visible !important;
}

.before-after-resizer hr {
  left: 0;
  top: 0;
  position: absolute;
  width: 2px;
  height: 100%;
  background: #efefef;
  align-self: center;
  z-index: 0;
  border: 0px;
  margin: 0;
}

.rnd-aaa img {
  /* width: 322px; */
  width: 100%;
  height: 100%;
  position: static;
  /* position:absolute; */
}

.rnd-aaa {
  overflow: hidden !important;
  height: 322px;

  z-index: 0;
  width: 100%;
  /* border-left: 2px solid brown; */
}

.rnd-resizer {
  /* border: 2px solid red; */

  background: rgba(81, 168, 177, 0.6);
}

.before-after-resizer .img {
  position: absolute;

  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  background-color: #efefef;
  align-self: center;
  z-index: 1;
  /* padding: 0 5px; */
  transform: translateX(35%);
  color: black;
  border-radius: 50%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.arrr {
  position: absolute;
  /* border: 2px solid red; */
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10;
}

.leftArr {
  transform: rotate(-90deg);
  /* border: 2px solid blue; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  /* background-color: #efefef; */
  /* margin-right: 10px; */
  right: 10px;
}
.rightArr {
  transform: rotate(90deg);
  /* border: 2px solid red; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: contain;
  /* background-color: #efefef; */
  /* margin-left: 10px; */
  left: 10px;
}

.rightArr,
.leftArr {
  position: absolute;
  width: 40px;
  height: 40px;
  overflow: hidden;
  fill: rgba(81, 168, 177, 1);
}

@media (min-width: 1200px) { 
  .rnd-aaa img { 
    width: 560px !important; 
  }
}


@media (max-width: 1199px) { 
    .rnd-aaa img { 
      width: calc((95vw - 20px) / 2) !important;
    }
  }
@media (max-width: 700px) { 
    .rnd-aaa img { 
      width: calc(95vw - 20px ) !important;
    }
  }
