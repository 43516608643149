.new-hero-layout {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  position: relative;
  /* margin-bottom: 20px; */
}

.nh-image {
  display: flex;
  top: 0;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.nh-image img { 
  width: 100%;
  height: 100%; 
  object-fit: cover;
  overflow: hidden;
}
/* .nh-image   {
 position: fixed;
} */
.nh-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  /* generated shadow  */
  background: #000000;
  background: -webkit-linear-gradient(91deg, #000000 0%, rgba(77, 69, 69, 0) 50%, rgba(71, 74, 255, 0) 100%);
  background: linear-gradient(91deg, #000000 0%, rgba(77, 69, 69, 0) 50%, rgba(71, 74, 255, 0) 100%);
}
.dToggler{
  position: absolute;
  top: 0;
  /* border: 2px solid red; */
  z-index: 1;
  height: 100vh;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;


}


.nh-text {
  z-index: 1;
  position: absolute;
  top: 0px;
  width: 53%;
  height: 100%;
  padding-left: 40px;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: aliceblue;
  /* border: 2px solid red; */
  font-family: Inter;
  font-size: 33px !important;
  font-style: normal;
  font-weight: 200 !important;
  transform: translate(-50%);
  opacity: 0;
  transition: 0.5s;
  overflow-wrap:unset;
  /* filter: drop-shadow(0px 0px 2px #000) */
}
.visibleCarouselText{ 
 /* transition: 0.5s !important; */
  opacity: 1 !important;
  transform: translate(0%) !important;
}

.nh-text h1 {
}

.nh-text h1 span {
  color: #51a8b1;
}
.slick-slider-destroy-gap{
  height: 100vh;
}

.dToggler{
  font-size: x-small;
}