
.course-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cp-header{
    font-family: Inter;
    color: var(--dark-color);
    padding: 40px;
    font-size: 20px;
}

.border-line{
    max-width: 1000px;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid rgb(189, 185, 185);
    font-family: Inter;
    margin: 0 0 70px 0;
}

.cp-image{
    width: 100%;
    height: 400px;
    overflow: hidden;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 6px;
}
.cp-image img{
    width: 100%;
    /* border: 2px solid red; */
}

.cp-title{
    padding: 20px 0;
    font-weight:lighter;
}

.cp-detail{
    color:  #828282; 
}
.cp-detail p{
    color:  #828282; 
    padding: 0;
}

.cp-item-list{
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.single-cp-item{
    color:  #828282; 
    width: 47%;
    height: fit-content;
    padding: 10px 0;
    display: flex;
    gap: 10px;
    /* align-items: center; */

}

@media(max-width:700px){
        
    .cp-item-list{
        
        flex-direction: column;
    }

    .single-cp-item{ 
        width: 100%; 
        height: auto;

    }
    .border-line{ 
        margin: 10px ;
    }
    .cp-image{ 
        height: 200px; 
    }
}

.loading-page{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    gap:10px;
    flex-direction: column;
  font-family: Inter;

}