
.heroComp {
    position: relative;
    display: flex;
    margin: 25px 0 0 0;
    flex-direction: column;
    height: auto;
    /* border: 1px solid rgba(217, 217, 217, 0.91); */
    /* border-radius: 0%; */
    align-items: center !important;
    /* position: relative; */
    overflow: hidden;

  }
  .heroComp .image {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-items: center !important; 
    overflow: hidden;
  }
  .heroComp .image img { 
     width: 100%;
     overflow:hidden;
    height: 95vb;
    position: relative; 
    object-fit:none;
  }
  
  .side-text {
    font-family: Inter;
    font-size: 20px;
    color: #fff;
    text-align: left;
    position: absolute;
  
    bottom: 60px;
    left: 10px;
    height: fit-content;
    margin: auto;
    padding-bottom: 75px;
    transform: translateX(-100%);
    transition: 1s;
    opacity: 0;
    line-height: 40px;
  }
  .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px -300px 160px -160px #000000;
      /* border-radius: 20px; */
      top: 0;
      left: 0;
  }
  
  .fade-text-in {
    transform: translateX(0%);
    transition: 1s;
    opacity: 1;
  }
  
  .side-text h1 span {
    color: #51a8b1;
    font-size: 60px;
    line-height: 65px;
    /* background: linear-gradient(
      1deg,
      rgba(0, 0, 0, 0.8) 9.26%,
      rgba(0, 0, 0, 0) 100%
    ); */
  }
  .side-text h1 {
    line-height: 40px;
  }