@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
.bottom-contact {
  /* overflow: hidden; */
  position: fixed;
  bottom: 0;
  width: 100%;
  justify-content: space-evenly;
  background: #51a8b1;
}
.jump-arrow {
  overflow: hidden;
  position: fixed;
  bottom: 50px;
  width: 18px;
  justify-content: right;
  align-items: center;
  align-self: center;
  background: #1a1919;
  border-radius: 3px;
  padding: 10px;
  right: 10px;
}
.call-us,
.text-us {
  width: 50vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.call-us {
  height: 100%;
  background: #1a1919;
  height: 100%;
  padding: 10px 0;
}

.call-us p,
.text-us p {
  color: aliceblue;
  padding-left: 10px;
  font-family: Inter;
}
.BC {
  /* transform: translateY(-10%); */
  transition: 0.2s;
  opacity: 1;
  border-radius: 50%;

}

.fade-in {
  /* transform: translateY(0) !important; */
  opacity: 1 !important;
  transition: 0.2s;
  box-shadow: 0 px 3px 4px 0px rgba(0, 0, 0, 0.9);
}

.whtsup {

  display: flex;
  overflow: hidden;
  position: fixed;
  bottom: 20px;
  width: 60px;
  /* height: 100?vh; */
  justify-content: center !important;
  align-items: center !important;
  align-self: center !important;
  padding: 0 0;
  margin: 0 0;
  right: 20px;
  /* text-decoration: none;  */
  height: 60px;
  fill: #59ce72;
  /* background-color: #115563; */
  background-color: white;
  border: 1px solid  rgb(119, 116, 116);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5.5px -1.5px #010101;
  -moz-box-shadow: 0 0 5.5px -1.5px #010101;
  box-shadow: 0 0 5.5px -1.5px #010101;
  cursor: pointer;
}
