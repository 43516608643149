.main-hero-section {
  display: flex;
  justify-content: center;
  flex-direction: column;

  border: 2px solid #002fff;
  padding: 0 0;
  margin: 0 0;
  /* overflow: hidden; */
}
.loading-header {
  width: 100% !important;
  height: 450px;
  padding-top: 60px;
  display: flex;
  align-items: center;
}
.hero-page {
  overflow: hidden;

  display: grid !important;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 400px 50px;
  /* border: 2px solid #fa2121; */
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 60px;
}

.ch-text {
  /* border: 1px solid #00ff00;  */
  padding: 0 20px;
  text-align: left;
  color: var(--darker-cyan, #166473);

  font-family: Inter;
  font-size: 23px !important;
  font-style: normal;
  font-weight: 200 !important;
  align-self: center !important;
  justify-self: center !important;
}
@media (max-width: 772px) {
  .ch-text {
    font-size: 15px;
  }
}

.ch-image {
  display: flex;
  justify-self: center;
  width: 100% !important ;
  height: 100%;
  overflow: hidden;
  /* border: 2px solid  #00ff00; */
  align-self: center;
}
.ch-image img {
  width: 100%;
  object-fit: cover;
  object-position: center !important;
  /* border: 2px solid  #2f0fe7; */
}
.tgl {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  z-index: 1;
  justify-self: center;
  align-self: stretch;
  /* border: 2px solid #e5ff00; */
  width: 100% !important ;
  height: 50px;
  padding-top: 40px;
}
.top-padding-sixty {
  padding-top: 60px;
  height: 200px;
}
.make-row {
  /* display: flex !important;
    align-items: center!important;
    gap: 10px !important; */
}

/* .row{
    color: red;
    display: flex;
    flex-direction: row;
    width: 100%;
  } */

.fit-width {
  width: 100% !important;
  overflow: hidden;
  /* border: 2px solid green; */
  padding: 0px !important;


}
.dark-60-bar {
  height: 73px;
  width: 100%;
  background: #f5f5f7;
  /* rgb(131, 126, 126); */
}
.ddark-60-bar {
  height: 80px;
  width: 100%;
  background: #f5f5f7;
  /* rgb(131, 126, 126); */
}
