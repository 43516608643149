.progTitle h2{
    color: var(--dark-color) !important;

}

.programs-header-image{
    width: 100%;
    /* max-width: 1200px; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 440px;
}

.programs-header-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width:700px){
    .programs-header-image{ 
        height: 100px;
    }
    .progTitle h2{
        padding: 0 20px !important;
        font-size: 30px; 
        
    }
    .should-i-question{
        width: 100%;
        max-width: 1200px;
        /* height: 400px; */
        height: auto !important;
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center !important;
    }
    .siq-image{
        width: 100% !important; 
        height: 100% !important;
    
    }
    .siq-text{
        width: 95% !important; 
        padding:  20px  0 !important;
        
    }
    
  
} 

.prog-fit-width{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}
.should-i-question{
    width: 100%;
    max-width: 1200px;
    /* height: 600px; */
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.siq-text{
    width: 45%;
    height: 100%;
    font-family: Inter;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    justify-content: flex-start;

    
}

.siq-title  {
    color: var(--dark-color);
    font-size: 45px;
    font-weight: 500;
}
.siq-title h1{
    
    font-weight: 500;
}

@media(max-width:960px){
    .siq-title  { 
        font-size: 35px !important; 
    }
}
@media(max-width:750px){
    .siq-title  { 
        font-size: 25px !important; 
    }
    .prog-fit-width{
        
        padding: 50px 0;
    }
}

@media(max-width:1200px){
    .siq-text{
        padding-left: 10px;
    }
    
.siq-para{
   
    padding-right: 30px !important;
    
 
}

}

@media(max-width:800px){ 
    
.siq-para{
   
    padding-right: 15px !important;
    
 
}

}
.siq-para{
    color: #828282;
    padding: 20px 40px 35px 0;
    align-self: flex-start !important;
    /* border: 2px solid red; */
    /* padding-bottom: 70px; */
    text-align: justify !important;

 
}
@media(max-width:700px){

    .siq-para {
    
        padding: 20px 0px 20px 0 !important;
 
}
}


.siq-image{
    width: 55%;
    height: 500px;
    /* height: 80%; */
    overflow: hidden; 
    /* border: 2px solid red; */

}

.siq-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  