#gradient-horizontal {
    --color-stop-1:   #f9ce34;
    --color-stop-2:   #ee2a7b;
    --color-stop-3: #6228d7;
  }

 
.chat-box-container {
  display: flex;
  justify-content: center;
  bottom: 90px;
  right: 15px;
  position: fixed;

}

.chat-box {
  height: 500px;
  width: 310px;
  margin: 5px 5px;
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 20px -10.5px #000000;
  -moz-box-shadow: 0 0 20px -10.5px #000000;
  box-shadow: 0 0 20px -10.5px #000000;
  background: white;
}
.chat-box-header {
  height: 70px;
  /* border: 2px solid yellow; */
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 20px;
  gap: 20px;
  background: darkcyan;
  color: white;
  font-family: Inter;
  justify-content: start;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(243, 241, 241);
}

.title {
  font-size: 10px;
}

.close-icon-button{
    align-self:flex-start !important;
    justify-self:flex-end !important;
    display: flex;
   padding-left: 70px;
   cursor: pointer;

}

.chat-box-body {
  display: flex;
  flex-direction: column;
  background: white;
}
.chat-bar {
  padding: 10px 20px 10px 20px;
}
.chat-time {
  font-size: 10px;
  text-align: center;
  padding: 14px 0 5px 0;
  color: grey;
}
.chat-box-body .avatar {
  width: 30px;
  height: 30px;
}

.chat-message {
  /* border: 1px solid red; */
  background: rgb(238, 250, 236);
  padding: 10px;
  border-radius: 0 10px 10px 10px;
  margin: 10px 0 0 7px;
  font-size: 13px;
  font-family: Inter;
}
.chat-message p {
  font-weight: 100 !important;
  color: rgb(112, 109, 109);
}
.cta {
  padding: 0px 0 0 0;

}

.cta-text {
  text-align: center;
  font-size: 12px;
  padding:      5px 0 0 0;
}

.cta-btn {
  background: white;
  border: 1px solid rgb(44, 44, 44);
  padding: 10px 13px;
  margin: 10px 20px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
  color: black;
  text-decoration: none;
}

.telegram-icon {
  height: 20px;
  fill: #0088cc
}
.whatsapp-icon {
  height: 20px;
  fill:#59ce72;
}
.instagram-icon {
  height: 20px;
  fill:  #ee2a7b;
 
}

.or{
    text-align: center;
}


.chatboxelf{
  height: 50px !important;
}