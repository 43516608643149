.single-service-testimony{
 display: flex;
 justify-content: center;
 /* border: 2px solid blue; */
 flex-direction: column;
 align-self: center;
 justify-self: center;
 margin: 0 !important;
}

.pleaseCenterUp{
    display: flex;
    justify-content: center;
    /* border: 2px solid brown; */
    flex-direction: column;
    align-self: center;
    justify-self: center;
    margin: 0 !important;
    
}
@media (min-width: 1200px) {
    .single-service-testimony{
      width: 560px !important;
        padding: 0 10px;

    }
   
      
  }
  
  
  @media (max-width: 1199px) {
    .single-service-testimony  {
        width: calc((95vw - 20px) / 2);
        padding: 0 10px;

      }
    }
  
  @media (max-width: 700px) {
    .single-service-testimony  {
        width: calc(95vw - 20px );
        padding: 0 10px;
      }
    }

.before-after-sign{
    display: flex;
    font-size: large;
    font-family: Inter;
    justify-content: space-between;
    padding: 10px 3px;

}

.before-after-sign h4{
    font-weight: 500;
}





.service-testimony-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    /* border: 2px solid red; */
    min-height: 100px;
    max-height: 400px;
}
.service-testimony-text p{
    padding: 0 3px;
    font-size: 12px;
}
.service-testifier{
    font-size: 35px;
    text-align: left;
}

.stestimony-text{
    align-items: flex-start !important;
}

.quoteIcon{
    font-size: 16px;
    padding-left: 3px;
    padding-top: 0;
    /* border: 2px solid red; */

}



.serivce-stars{
    display: flex;
    column-gap: 15px !important;
  color: orangered;
    /* border: 2px solid red; */

}

.serivce-test-text{
  font-size: 14px !important;
}