* {
  padding: 0;
  margin: 0;
} 
.landing-page-views{  
  max-width: 100%;
}

header.landing-navs {
  background: #51a8b1;
  padding-right: 30% !important;
  padding: 0 10px 10px 0;
  width: 100%;
}
.landing-navs nav img{
  padding-left: 10px;
}
.landing-navs nav p {
  color: #fff;
  font-size: small;
  padding: 10px 0 0 0px;
  text-align: right;
  padding-right: 10px !important;
}

.center-bodys{
  display: flex;
  flex-direction: column;
}
/* .centerFills{
  height: 50vh;
  overflow: hidden;
} */

