.single-testimony {
  width: 80% !important;
  display: flex;
  gap: 10px !important;
  /* border: 2px solid black; */
  padding: 10px 0 10px 0;
  margin: 0 0px;
  flex-direction: column;
}

.three-testimony {
  width: 100% !important;

  justify-content: space-between !important;
  display: flex !important;
  /* border-radius:  6.626px;
    border: 1px solid rgba(217, 217, 217, 0.91);  */
}
 
.testimony-avatar img {
  /* width: 100% !important; */
  height: 100%  !important;
  object-fit: fill;
  /* border: 1px solid  blue; */

}
.testimony-avatar {
  width: 250px !important;
  height: 250px !important;
  /* border: 1px solid  red; */
  align-self: center  ;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.testimony-text {
  gap: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  width: auto !important;
  height: 100% !important;
  text-align: center !important;
  align-items: center !important;
  align-content: center !important;
  justify-content: center !important;
}
.test-text {
  font-size: 12px;
  /* display: flex; */
  /* width: 100vh; */
}

.testimonials h2 {
  font-size: 40px;
  padding: 30px 0;
  font-weight: 500;
  color:  #166473;

}
 
/* 
.testimonials {
  padding: 30px 0px;
} */

.testifier h3 {
  text-align: left !important;
}

.fit-testamony {
  /* width: 100% !important; */
  /* border: 1px solid red; */
  align-self: center;
  justify-content: center !important;
}

.center {
  text-align: center;
}

.edge-div div {
  margin: 0;
  padding-top: 3px;
}
.stars {
  display: flex;
  color: orangered;
  /* align-self: left;  */
  /* align-self: center; */
  /* width: 100%; */
  justify-content: space-evenly;
  padding-left: 20%;
  padding-right: 20%;
}

.testimonials {
  display: flex;
  /* height: 200px; */
  flex-direction: column;
  background: #f5f5f7;
  margin: 0 0 !important;
  padding: 0 0 !important;
  font-family: Inter;
  width: 100%;
  /* border: 2px solid brown; */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  
  
}

.testifier {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.testifier h5 {
  /* padding-left: 10px; */
  font-size: large;
  font-weight: 300;


}

.test-text {
  padding: 0 0 10px 0;
  font-size: 14px;
}
 
@media (min-width: 700px) {
  
.testimonials { 
  padding: 0 0 30px 0 !important; 
  
  
}
  .test-text {
    font-size: 11px;
    padding: 0;  
  }
 
  .single-testimony {
    display: flex;
    flex-direction: row !important;

  }
  .testimony-avatar img {
    width: auto !important;
    height: 100% !important;
    /* width: 100% !important; */
  }
  .testimony-avatar {
    min-width: 200px !important;
    height: 200px !important;
    overflow: hidden;
    background: #51A8B1;
    display: flex;
    justify-content: center;
  }
  .single-testimony {
    width: auto !important;
    display: flex !important;
    gap: 10px !important;
    /* border: 2px solid black; */
    padding: 10px 0 10px 0;
    margin: 0 10px;
    flex-direction: column;
  }
  .testimonials h2 {
    font-size: 40px;
    padding: 40px 0 60px 0;
    font-weight: 500;
  }
  .testimony-text {
    gap: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    width: auto !important;
    height: auto !important;
    text-align: left !important;
    align-items: flex-start !important;
    align-content: left !important;
    justify-content: space-between !important;
    /* border: 2px solid red; */

  }
  .stars {
    display: flex;
    color: orangered;
    /* align-self: left;  */
    /* align-self: center; */
    /* width: 100%; */
    justify-content: space-evenly;
    padding-left: 0%;
    padding-right: 0%;
    gap: 10px;
  }
  .testifier h5 {
    /* padding-left: 10px; */
    font-size: 15px;
    font-weight: 500;
    
  }
}
