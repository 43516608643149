* {
  padding: 0;
  margin: 0;
}

header.landing-nav {
  background: #51a8b1;
  padding-right: 30% !important;
}

.landing-nav nav p {
  color: #fff;
  font-size: small;
  padding: 10px 0 0 0px;
  text-align: right;
  padding-right: 10px !important;
}
.social-media-dark {
  padding: 10px 0;
  justify-content: space-evenly;
  /* padding: 8% 5% 0 5%; */
  background: #51a8b1;

  /* background-color: #333; */
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.social-media-dark img {
  /* background: #fff; */
  z-index: 1;
}
.blackAndWhites {
  /* filter: brightness(0.8) saturate(33%);
  z-index: -1 !important;
  overflow: hidden;  */
}
.nones {
  background: -webkit-radial-gradient(
    circle,
    rgba(71, 74, 255, 0) 0%,
    rgba(61, 55, 55, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  background: radial-gradient(
    circle,
    rgba(71, 74, 255, 0) 0%,
    rgba(61, 55, 55, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  ) !important;
  transition: 1s !important;
  position: absolute !important;
}
.moving-shadow {
  box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;
  -webkit-box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;
  -moz-box-shadow: 4px 3px 93px 71px rgba(0, 0, 0, 0.69) inset;

  filter: brightness(0.8) saturate(33%);
  /* position: absolute;
  height: 50vh;
  width: 100%;  
  background: rgba(71, 74, 255, 0);
  background: -webkit-radial-gradient(
    circle,
    rgba(71, 74, 255, 0) 0%,
    rgba(61, 55, 55, 0.47) 50%,
    rgba(0, 0, 0, 0.71) 100%
  );
  background: radial-gradient(
    circle,
    rgba(71, 74, 255, 0) 0%,
    rgba(61, 55, 55, 0.47) 50%,
    rgba(0, 0, 0, 0.71) 100%
  );*/
  transition: 1s !important;
}
.clean-service {
  /* align-self: stretch; */
  width: 100vw;
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("assets/service.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  background-position: center;
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  padding: 40px 0 0 0;
  min-height: 46vh;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 1s;
  position: relative;
  /* width: 100% !important; */
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden;
}
.clean-training img{
  width: 100% !important;
  overflow: hidden;
}
.clean-service img{
  width: 100% !important;
  overflow: hidden;

}
.clean-training {
  position: relative;

  border-top: 2px solid white !important;
  width: 100vw;

  flex: 1 0 0;
  align-self: stretch;
  /* height: 100vh; */
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("assets/traning.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  background-position: center;
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  /* padding: auto; */
  min-height: 46vh;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 1s; 
  overflow: hidden;
}

.service-tab {
  flex: 1 0 0;
  /* align-self: stretch; */
  /* height: 100vh; */
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("assets/service.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  background-position: center;
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  padding: 40px 0 0 0;
  min-height: 46vh;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 1s;
}

.training-tab {
  flex: 1 0 0;
  align-self: stretch;
  /* height: 100vh; */
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("assets/traning.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  background-position: center;
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  /* padding: auto; */
  min-height: 46vh;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: 1s;
}
.landing-menu {
  position: absolute;
  z-index: 90;
  padding: 100px;
  color: var(--white, #f5f5f7);
  text-align: center;
  /* Mobile XXlarge */
  font-family: Inter;
  font-size: 38.056px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}

.desktop-bar {
  display: flex;

  padding: 15px 0;
  justify-content: space-evenly;
  /* padding: 8% 5% 0 5%; */
  background: #51a8b1;

  /* background-color: #333; */
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #f5f5f7;
  align-items: center;
  margin-left: 70px;
}

.desktop-bar .icons img {
  justify-content: space-evenly;
  margin-left: 30px;
}

.desktop-vew {
  display: flex;
  /* background: #51a8b1 ; */
}

.desktop-landing-nav {
  width: 80px;
  background: #51a8b1 !important;
  padding: 0 !important;
  bottom: 0 !important;
  height: 100vh;
  border: 1px solid rgba(217, 217, 217, 0.91);
  border-radius: 1px;
  position: fixed;
  z-index: 4;
}
.desktop-view .desktop-landing-nav nav {
  background: #51a8b1;
}

/* @media (min-width: 700px) { */
.center-body {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
align-items: center;
align-self: center;
justify-self: center; */
  width: 100vh;
  height: 100vh;
  /* align-items: center;
gap: 1px;*/
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6.626px;
  border: 1px solid rgba(217, 217, 217, 0.91);
}
.desktop-training-tab {
  flex: 1 0 0;
  align-self: stretch;
  /* height: 100vh; */
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("assets/traning.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  /* background-position: center; */
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  /* padding: auto; */
  /* min-height: auto; */
  /* width: auto; */
  /* width: 100vh;
  height: 100vh; */
  justify-content: center;
  /* align-items: center; */
  /* gap: 10px; */
}
.desktop-service-tab {
  flex: 1 0 0;
  /* align-self: stretch; */
  /* height: 100vh; */
  /* background: radial-gradient(
      54.53% 54.53% at 50% 45.47%,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url("assets/traning.png"), lightgray 50% / cover no-repeat; */
  /* width:100vh; */
  background-position: center;
  background-size: cover;
  /* max-width: fit-content; */

  display: flex;
  /* padding: auto; */
  /* min-height: 100VH; */
  /* min-width: 50; */
  /* width: auto;
  height: 100vh; */

  justify-content: center;
  /* align-items: center; */
  /* gap: 10px; */
}
/* } */

.desktop-serum-logo {
  padding: 10px;
}

.centerFill {
  display: flex;
  align-self: stretch;
  /* justify-content: flex-start; */
  flex-direction: column;
  height: auto;
  width: 100%;
  /* align-items: flex-start; */
  /* gap: 10px; */
  background: #fff;
  position: relative;
}

/* ///////////////////////////////SECOND SLATE?????????????????  */
.landing-page-view {
  width: 94%;
  height: 90vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* border: 1px solid rgba(217, 217, 217, 0.91);
  border-radius: 1px; */
}

.main-body {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 0 80px;

  height: 90px;
  /* border: 1px solid rgba(217, 217, 217, 0.91);
  border-radius: 1px; */
}

.mainOne,
.mainTwo {
  width: 100%;
  height: 100vh;
  display: flex;
  align-self: center;
  position: relative;
}

.backImage {
  position: inherit;
  display: flex;
  justify-content: center;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  align-items: center;
  transition: 0.4s;
  /* border-right: 2px solid #fff; */
  background-size: cover !important;
  background-position: center !important;
  /* background: url("assets/traning.png"), lightgray 50% / contain no-repeat; */
  filter: brightness(0.5) saturate(0%);
  box-shadow: inset 0px -400px 160px -160px #000000e7;

}
.mainText {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 30px;
  font-family: Inter;
  z-index: 99 !important;
  transition: 0.4s;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.backImage img {
  position: fixed;
  z-index: 0;
  height: auto;
  width: auto;
}
.hvr:hover .backImage {
  transition: 0.2s;
  /* filter: brightness(1) saturate(100%); */
  box-shadow: inset 0px -400px 160px -160px #000000e7;
  /* transition: 0.2s; */
  transition: 0.8s;
  filter: brightness(1) saturate(100%);
} 

.hvr:hover .mainText {
  padding-top: 30% !important;
  /* align-items: flex-end; */
  transition: 0.4s;
}
.vertical-rode {
  width: 1px;
  height: calc(100% - 60px);
  left: calc(50% + 50px);
  background: white;
  z-index:9999;

  position: absolute;

}
