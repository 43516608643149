.lp-header {
  font-size: 18px;
  color: var(--dark-color);
  font-family: Inter;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  text-align: center;
}
.librariesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 80px 20px;
  /* border: 2px solid blue; */
overflow: hidden;
}
.libraries-title{
   width: 100%;
    max-width: 1200px;

  /* border: 2px solid blue; */

  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0;
}
.sliding-library{
   width: 100%;
    max-width: 1200px;
    /* border: 2px solid blue;  */
   
  padding: 10px 0;
}
.sliding-librarys{
   width: 100%;
    max-width: 1200px;
    /* border: 2px solid blue;  */
   
  padding: 0 !important;
}

.lflex-card {
  padding: 0px !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  overflow: hidden !important;
  justify-content: center !important;
  align-items: center;
  /* border: 2px solid blue; */
  width: 100%;

}

@media (max-width: 700px) {
  .librariesPage {
    display: flex;
    justify-content: center;
    /* padding: 30px 10px;  */

  }
  .lflex-card {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 20px;
    max-width: 1200px;
    margin: 0 10px !important;
    overflow: hidden;
  }
  .lflex-card {
    padding: 0 0px !important;
  }
}

@media (max-width: 1200px) {
  .lflex-card {
    margin: 0px 20px;
  }
}
@media (max-width: 800px) {
  .lflex-card {
    grid-template-columns: repeat(2, 1fr);

    margin: 0px 20px;
  }
}

.link {
  text-decoration: none !important;
}

.rowsl{
    display: flex;
    gap:20px !important;
    /* border: 2px solid blue; */
}