:root {
  --primary: #fff;
}
.btn{
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.24);
}
.btn--big {
  padding: 20px 20px;
  gap: 10px;
  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Inter;
  font-weight: bold;
   height: 60px;
  width: 190px;
}
.btn--small {
  padding: 13px 18px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: normal;
  font-family: Inter;
  font-size: 15px;
  
  height: 45px;
  width: 150px;
}

.btn--white {
  background-color: var(--primary);
  color: #166473;
  border: 1px solid #166473;;
  
}
.btn--dark {
  background-color: #166473;
  color: var(--primary);
  border: #166473;
}
