.desktop-last-header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid blue; */
  z-index: 100;
  position: fixed;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4.400000095367432px);
  /* padding: 0 15px !important; */

}

.sv-desktop{
  /* border: 4px solid red; */
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.navbarbutt{
  padding-right: 10px;
}
.sv-desktop-nav-links {
 display: flex;
 align-items: center;
 justify-content: space-between;
 width:100%;
 max-width: 1220px;
 /* border: 1px solid red; */
 /* gap: 2%; */
 height: 80px;
}
.desktop-last-header ul {
  align-items: center;
  display: flex;
  font-family: Inter;
  list-style: none;
  text-align: center;
  align-self: center;
  justify-content: center; 
  /* border: 1px solid green; */
  /* padding: 0 40px; */
  gap: 12px;

}

.desktop-last-header ul li {
  /* padding:0  20px 0px 20px; */
  font-size: 12px;
  /* font-size: 12px; */
  align-self: center;
  /* border: 1px solid yellow; */
  display: flex;
  flex-direction: column; 
}

  .sv-desktop-nav-links ul li .menuLink{
    padding: 0px 20px;
    font-size: 16px; 
    /* font-size: 14px;  */
   
  /* border: 1px solid red; */

  }

  
  .sv-desktop-nav-links ul li .menuLink:hover{
      color:   #51a8b1 !important;
      text-decoration-color:  #51a8b1;

      transition: 0.1s;

  }
  .sv-desktop-nav-links ul li .menuLink:active{
      color:  #166473 !important;
      text-decoration-color: #166473;
 

  }

  @media(max-width:1100px){
    .sv-desktop-nav-links ul li .menuLink{ 
      font-size: 13px;  

  
    }

  }
  .mobile-nav-bar{
    align-items: center;
    position: fixed;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(4.400000095367432px);
  }
.header-button{
  text-decoration: underline;
  font-family: Inter;
  color: #166473 ;
  padding-right: 10px;
}
  