.cert-section{
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0 50px 0;
    max-width: 1200px;
    display: flex;
    justify-content: center;
}

.cs-image  {
    width: 50%;
    margin: 0 10px;
}
.cs-image img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}
@media(max-width:950px){
    .cert-text{
        
        font-size: 14px !important;
        
    }
}
/* @media(max-width:780px){
    .cert-text{
        
        font-size: 10px !important;
        
    }
} */

.cert-text{
    font-family: Inter;
    font-size: 16px;
    width: 50%;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: space-around;
    /* height: 100%; */
    font-weight: lighter;

    color: #828282;
}

.cert-text h4{
    font-weight: lighter;
}

@media (max-width:700px) {

    .cert-section{
        flex-direction: column;
        align-items: center; 
        padding: 0 20px;
    }
    .cs-image  {
        width: 100%;
    }
    .cert-text  {
        padding: 0 10px;
        width: 100%;

    }
    .cert-text h4{
       padding: 10px 0;
    }
}


.cert-library-image{
    height: 500px;
    overflow: hidden;
    width: 80%;
}

.cert-library-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.certsliding-library{
    width: 100% !important;
    padding-bottom: 60px ;
    overflow: hidden;
    /* border: 1px solid red; */
}
@media(max-width:700px){
    .certsliding-library{
        /* width: 100% !important; */
        padding-bottom: 60px;
        padding-top: 50px;
        /* overflow: hidden; */
    
    }
}
@media(max-width:365px){
    .certsliding-library{
        /* width: 100% !important; */
        padding-bottom: 40px;
        padding-top: 30px;
        /* overflow: hidden; */
    
    }
}
@media(max-width:912px){
    
.cert-library-image{
    height: 400px;
    overflow: hidden;
    width: 40%;
}
}
@media(max-width:750px){
    
.cert-library-image{
    height: 300px;
    overflow: hidden;
    width: 30%;
}
}
@media(max-width:600px){
    
.cert-library-image{
    height: 250px;
    overflow: hidden;
    width: 25%;
}
}
@media(max-width:500px){
    
.cert-library-image{
    height: 200px;
    overflow: hidden;
    width: 20%;
}
}
@media(max-width:440px){
    
.cert-library-image{
    height: 150px;
    overflow: hidden;
    width: 15%;
}
}
@media(max-width:360px){
    
.cert-library-image{
    height: 100px;
    overflow: hidden;
    width: 15%;
}
}

.padding-60{
    padding-top: 20px;
}


@media(min-width:1300px){
    
    .cert-library-image{
        height: 450px;
        overflow: hidden;
        width: 20%; 
    }
  
    }  .cert-library-image img{ 
        width: 95%; 
    }