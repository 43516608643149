@media (min-width: 700px) {
  .treatments-desktop {
    display: flex;
    gap: 10px !important;
    margin: 0 10px;
    max-width: 1200px;
    justify-self: center;
    align-self: center;
    flex-direction: row !important;
    /* border: 1px solid red; */
    margin-bottom: 10px !important;
  }
}

.treatments-desktop {
  display: flex;
  gap: 10px;
  margin: 0 0;
  /* max-width: 1300px; */
  justify-self: center;
  align-self: center;
  /* flex-wrap: wrap;
  justify-content: center; */
  /*  */
  flex-direction: column;
  /* border: 2px solid red; */
 
}
.treatment-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  /* border: 2px solid red; */

}