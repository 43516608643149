.photo-grid{
    display: grid;
    grid-template-columns: repeat(6, 1fr );
    grid-template-rows: repeat(3, 200px );
    /* width: 100%; */
    gap: 10px;
    padding: 0 10px;
}
.el{
    /* background: aqua;
    border: 2px solid red; */
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.el img{ 
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.item-2{
    grid-column: 2 / 4;
    grid-row: 1 / 3;
}

.item-7{
    grid-column: 4 / 6;
    grid-row: 2 / 4;
}

.top-padding-ten{
    margin-top: 10px !important;
}