.slibrary-card{
    padding-bottom: 20px;
    margin: 0 20px;
   width:90%;
    /* border: 2px solid greenyellow; */

}

.slc-image{
    width:100%;
    height: 250px;
    overflow: hidden;
}
.slc-image img{
   width:100%;
   height: 100%;
   object-fit: cover;
    /* height: 300px; */
    /* border: 2px solid red; */

}

.slc-text{
    font-family: Inter;
    font-size: 12px;
    padding: 5px 0;
    font-weight: 300 !important;
    cursor: pointer;
}
.link h3{
    text-decoration: underline !important;
    color: black;

}
.slc-text h3{ 
    font-weight: 500 !important;
    text-decoration: underline;
}
.slc-text h5{
    color: #828282;
    font-size: 10px;
    padding: 5px 0 0 0;

}

@media(max-width:700px){
    
    .slc-image {
       height: 150px;
       overflow: hidden;
       display: flex;
       align-items: center;
     
     }
     
}