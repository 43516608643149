.DNB {
  display: grid;
  grid-template-columns: 100px 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%; 
  overflow: hidden;
}

.c { 
  background: blueviolet;
}

.BB {
  grid-column: 2 / 4;
  z-index: 99;
}
.LB {
  grid-row: 1 /3;
  z-index: 101;
}

/* LB  */

.LBC {
  width: 100px;
  background: #51a8b1 !important;
  padding: 0 !important;
  bottom: 0 !important;
  height: 100vh;
  border-right: 1px solid rgba(217, 217, 217, 0.91);
  border-radius: 1px;
  position: fixed;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LB-LOGO {
  width: 80%;
}

/* BB  */
.BBC {
  display: flex;
  padding: 15px 0;
  justify-content: space-around;
  background: #51a8b1;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  /* width: calc(100% - 50px); */
  width: 100%;
  color: #f5f5f7;
  align-items: center;
  height: 30px;

  /* border: 2px solid red; */
}

.BBC .icons img {
  justify-content: space-evenly;
  margin-right: 30px;
  width: 20px;
}

.BBC p{
    font-size: 15px;
    font-family: Inter;
  /* border: 2px solid red; */

}

@media (max-width:800px){
    
.BBC .icons img { 
    margin-right: 20px;
    width: 15px;
  }
  
  .BBC p{
      font-size: 10px; 
  }
}


/* MS  */
.SS{
  /* border-right: 2px solid white; */
  /* border: 2px solid rgb(161, 57, 57); */
  /* height: 100%; */
  overflow: hidden; 


}
.TS{
  /* border-right: 2px solid white; */
  /* border: 2px solid rgb(161, 57, 57); */
  /* height: 100%; */
  overflow: hidden; 

}
.height25{
  height: 25px;

}
.tiktokIconH{
  /* width: 19px; */
  height: 25px;
  font-size: 19px;
  fill: white !important;
  padding-right: 0px !important;
  cursor: pointer;
  /* border: 2px solid red; */
  /* color: white; */
  margin: 0 !important;
}

.makewhite{
  filter: brightness(1200%);
}

@media(min-width:700px){
  .tiktokIconH{ 
    padding-right: 120px;
    /* width: 16px; */
    height: 20px;
    width: 16px;
  }
}