@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script:wght@400;500;600;700&display=swap");
body  {
  /* width: 100% !important ; */
  /* height: 100%; */
  /* position: fixed; */
}
.spinner {
  animation: spin infinite 2s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}
.loading {
  display: flex;
  justify-content: center;
  gap: 180px;
  padding: 20px 0;
}
.loading-car {
  display: flex;
  justify-content: center;
  gap: 180px;
  padding: 30px 0;
  /* border: 2px solid red; */
  height: 100% !important;
  align-items: center;

}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.row {
  display: flex;
}
.serum-logo {
  padding: auto;
  margin: 10px;
  /* width: 61.753px; */
  height: 53.575px;
  /* border: 1px solid green; */
}
.serum-logo-desk {
  padding: auto;
  margin: 10px 10px 10px 0 ;
  height: 53.575px !important;
  /* width: 100px !important;  */
}
 
nav {
  display: flex;
  align-content: space-between;
  width: 100%;
  justify-content: space-between;
  /* position: fixed; */
}

.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  /* position: relative; */
}
/* .search{

} */
.menuBar {
  color: var(--darker-cyan, #166473);
  z-index: 0 !important;
  padding-right: 20px !important;
}

/* COURSE CARD  */

.cardContainer {
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  height: auto;
  padding: 20px 20px;
  /* align-items: flex-start; */
  /* gap: 10px; */
  align-self: stretch;
  border-radius: 14px;
  border: 1px solid rgba(217, 217, 217, 0.91);
  background: #fff;
  position: relative;
  max-width: 300px;
}
.cardContainer .image {
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12.626px;
  /* background: url("components/assets/facemask.png"), lightgray 50% / cover no-repeat; */
  /* background: #fff, lightgray 50%; */
  object-fit: cover;

  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.cardContainer .image img {
  /* flex: 1 0 0; */
  /* align-self: stretch; */
  border-radius: 14px;
  /* background: url("components/assets/facemask.png"), lightgray 50% / cover no-repeat; */
  width: 100%;
  /* height: 200px; */
  max-width: fit-content;
  /* position: center; */
  /* size:cover; */
  /* z-index: 0; */
  object-fit: cover;
  position: relative;
  z-index: 0;
}
.courseTitle {
  color: #00080a;
  padding: 10px 0 0;
  /* DisplayMedium */
  font-family: Inter;
  font-size: 21.002px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.card-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 10px; */
  align-self: stretch;
  padding: 10px 0 0;
}
.cardContainer .card-text p {
  align-self: stretch;
  color: var(--grey, #828282);

  /* Mobile Paragraph */
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cardContainer .card-text h4 {
  font-family: Inter;
  font-weight: 500;
}
.explore-more {
  padding: 30px 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #166473;
}
.explore-more p {
  font-size: 29px;
}
.cardLink {
  padding: 0 10px 0 0;
  color: #166473;
  /* Mobile LInk */
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  font-family: Inter;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}
.learn-more {
  padding: 10px 0 0 0;
  color: #166473;
  /* Mobile LInk */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}
/* CTA  */

.CTA {
  background: #166473;
  /* padding: 60% 0; */
  height: 100vh;
  /* margin: 20px 0; */
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  /* align-items: center; */
}
.CTA h2 {
  padding: 0 0 20px 0;

  color: var(--white, #f5f5f7);
  text-align: center;
  /* DisplayXlarge */
  font-family: Inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.CTA ul li {
  color: #fff;
  padding: 0 15px 30px 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  font-family: Inter;
}
/* .CTA ul li {
  color: #fff;
  padding: 0 0 10px 0;
  font-size: 12px;
  text-decoration: none;
} */
.CTA ul {
  text-align: center;
  list-style-position: inside;
}

/* FOOTER  */

.footer {
  background: #f5f5f7;
  padding: 5% 0;
}

.logo-contact {
  justify-content: space-evenly;
}

.logo-contact .serum-logo {
  /* width: 150px; */
  height: 90px;
}

.logo-contact .btn {
  margin: 10% 0;
}
.footer p {
  color: var(--black, #00080a);
  text-align: center;

  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
  padding: 5% 10%;
}

.footer h3 {
  text-align: center;
  font-family: Inter;
}
hr.solid {
  border-top: 1px solid #f0efef;
}
hr.solidsdk {
  width: 100%;
  max-width: 1200px;
  border-top: 1px solid #f0efef;
}

.social-media {
  justify-content: space-evenly;
  padding: 8% 5% 0 5%;
}
  .andessa-it,  .andessa-it a {
    padding: 0 !important;
    margin: 0  !important;
    text-decoration: none !important;
    color: #131212 !important;

  }
  
 .anbessaitspan{
    text-decoration: none !important;
    color: #131212 !important;
    font-weight: bold;

  }

/* menu  */
/* .menu:hover{
 
 
    overflow-y: hidden;
  
} */
.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 30px 100px 40px 0;
  justify-content: center;
  justify-self: center !important;
  align-items: center !important;
  align-self: center !important;
  /* position: fixed; */
  /* border: 2px solid red; */
  z-index: 1;
  /* min-height: 50vh !important; */
  height: 60vh !important;
}

.menuLink {
  color: #000;
  padding: 0px 0 10px 0;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  align-self: center;
  /* margin-top: 10px; */
  transition: 2s;
  /* text-decoration: none !important; */
}

/* .menuLink:hover {
  text-decoration: underline !important;
  transition: 2s !important;
} */

/* hover animation */

/* .menuLink::after {
  content: "";
  width: 0%;
  height: 2px;
  background: black;
  display: block;
  margin: auto;
  transition: 0.5s;
}
.menuLink:hover::after {
  width: 100%;
} */

.menu ul {
  text-align: center;
  justify-content: space-around;

  padding-top: 20px;
  list-style: none;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* BEFORE LAST COMMENT  */
/*  
.menu ul {
  text-align: center;
  justify-content: center;
  
  padding-top: 20px;
  list-style: none;
} */
.menu ul li {
  /* padding: 30px 0; */
  font-family: Inter;
}

.closeMenuBar {
  /* text-align: right; */
  justify-content: right;
  justify-self: flex-end !important;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 20px;
  /* font-weight: 600; */
  /* border: 2px solid red; */
  position: absolute;
  align-items: center;
  align-self: flex-end !important;
  bottom: 0;
  right: 0;
}
.closeMenuBar p {
  font-family: Inter;
  padding-right: 15px;
}

/* SERVICE CARD  */
.sshadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -300px 160px -160px #000000;
  /* border-radius: 20px; */
  top: 0;
  left: 0;
  z-index: -11;
  transition: 1s !important;
}
@media (max-width: 700px) {
  

  .serviceCardContainer1 {
    width: 100% !important;
  }
  /* the followin next three selectors where commented out by request of Feysel to have hover effect on the mobile service card as well */
  .serviceCardContainer1 .image img {
    /* filter: brightness(1) saturate(100%) !important; */
  }
  .serviceCardContainer1 .text {
    /* justify-content: flex-end; */
    /* padding-top: 240px; */
  }
  .sc-shadow {
    /* box-shadow: inset 0px -400px 160px -160px #000000e7;
    z-index: 19;
    width: 100%;
    height: 100%; */
  }
  .sshadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px -300px 160px -160px #000000;
   
    top: 0;
    left: 0;
    z-index: 0;
    transition: 1s !important;
  }
}
.serviceCardContainer1 {
  /* border: 2px solid red; */
  height: 400px !important;

  position: relative;
  /* width: 30vw; */
  overflow: hidden;
  z-index: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/* 
@media (max-width: 950px) {
  .serviceCardContainer1 {
    width: 45vw;
  }
  .serviceCardContainer1 .image {
    width: 100%;
  }
} */
.serviceCardContainer1 .image {
  /* border: 2px solid red; */
  /* width: 100%; */
  height: 100%;
  width: 100%;
  /* height: 370px; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  object-fit: cover;
}
.serviceCardContainer1 .text {
  position: absolute;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  height: 100% !important;
  overflow: hidden;
  transition: 0.5s;
}
.gedi-filter {
  position: absolute; 
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* max-width: 30vw; */
  object-fit: cover;
  transition: 0.7s;
  background: rgba(81, 168, 177, 0.6);
}
.serviceCardContainer1 .image img {
  min-height: 100%;
  min-width: 100%;
  /* max-width: 30vw; */
  object-fit: cover;

  transition: 0.7s;
}
.serviceCardContainer1:hover .gedi-filter {
  transition: 0.7s;

  background: rgba(81, 168, 177, 0);
}
.serviceCardContainer1:hover .text {
  /* justify-content: flex-end; */
  padding-top: 240px;
  transition: 0.5s;
}

.serviceTitle {
  color: var(--white, #f5f5f7);
  text-align: center;
  /* Mobilw XLarge */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  /* padding: 40px 0 0 0; */
  font-weight: 700;
  line-height: normal;
  /* border: 4px solid red; */
}
.service-card-text {
  color: var(--white, #f5f5f7);
  text-align: center;

  /* Mobile Paragraph */
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* border: 4px solid red; */
}
.serviceCardContainer1 .service-card-text p {
  align-self: stretch;

  /* Mobile Paragraph */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
}
.serviceCardContainer1 .service-card-text h4 {
  font-family: Inter;
  font-weight: 500;
}

.service-card-text .learn-more {
  /* padding: 10px 0 0 0; */
  color: #fff;
  /* Mobile LInk */
  font-size: 13px;
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
  text-decoration-line: underline;
  text-underline-offset: 3px;
  align-self: center;
  cursor: pointer;
}

.link {
  text-decoration: none;
}

/* GLOBAL CSS  */

.invisible {
  visibility: hidden;
  transition: 0.2s;
}
.dark-60-bar {
  height: 80px;
  width: 100%;
  background: #f5f5f7;
  /* rgb(131, 126, 126); */
}

:root {
  --dark-color: #166473;
  --light-color: #51a8b1;
  --white-color: #f5f5f7;
  --black-color: #00080a;
}
.uidio {
  position: relative;
  min-width: 200px;
  width: auto;
  min-height: 40px;
}
.loading-form-elf {
  width: 100%;
  position: absolute;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.elf-z-top {
  z-index: 4 !important;
}

.elf-z-top5 {
  z-index: 5 !important;
}
.loading-form-elf h4 {
  font-family: Inter;
  font-size: 12px;
  padding: 10px 0;
}

.form-padding {
  padding: 10px 10px 40px;
  margin-top: 5px;
  /* width: 100%; */
  /* height: 100%; */
  /* position: absolute; */
  /* overflow: hidden; */
  /* border: 2px solid red !important; */
  overflow-x: hidden;
}
@media (max-width: 700px) {
  .form-padding {
    padding: 0 10px 40px 10px;
    /* border: 2px solid red; */
  }
}
.bootstrapDialog {
  /* width: fit-content;border: 2px solid rgb(55, 0, 255); */
}
.dialog-container {
}
.matui {
  /* margin: 0 90px !important;  */
  position: relative !important;
  font-family: Inter !important;
  overflow: hidden;
}

.hidden-things {
  visibility: hidden;
}

/* PRELOADER  */

.preloader {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  color: White;
  background: rgb(255, 255, 255);
  position: fixed;
}
.loading-mobile-header {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}
.preloader img {
  width: 100px;
}
/* IN PAGE LOADER  */
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;
  gap: 10px;
  flex-direction: column;
  font-family: Inter;
}

.glob-fit-width-flex {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0; */
}
.fillWhite {
  fill: white !important;
  color: white !important;
}

.dialog-tit {
  background: #166473;
  width: 100%;
  color: white;
  border-radius: 5px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  position: relative;
  min-width: 450px;
}

.dialog-tit h1 {
  font-weight: 500 !important;
  text-align: center;
  /* border: 1px solid red; */

  font-size: 30px;
}
.dialog-tit a {
  /* justify-self: flex-end !important; */
  /* border: 1px solid red; */
  align-self: center;
  height: 100%;
  position: absolute;
  right: 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: #b6b4b4;
}

@media (max-width: 700px) {
  .dialog-tit h1 {
    font-size: 20px;
  }

  .header-gap {
    padding-top: 0px !important;
  }
  .header-gap-smaller {
    padding-top: 0px !important;
  }

  .dialog-tit {
    min-width: 320px;
  }
}
@media (max-width: 400px) {
  .dialog-tit h1 {
    font-size: 15px;
  }
  .dialog-tit a {
    font-size: 15px;
  }

  .dialog-tit {
    min-width: 250px !important;
  }
}
@media (max-width: 350px) {
  /* .dialog-tit {
    min-width: 200px;
  } */
  .dialog-tit {
    min-width: 230px !important;
  }
}
.header-gap {
  padding-top: 56px;
}
.header-gap-not-smaller {
  padding-top: 10px;
}
.header-gap-smaller {
  padding-top: 20px;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.underlined-link{
  text-decoration: underline;
  color: black;
  padding: 10px;
}